import React, {useEffect} from 'react'
import axios from 'axios';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Test() {

    const Email = ReactSession.get("email");

   const  getWhatsapp=()=>{
       const emailDiv = document.getElementById('email');
       const waDiv = document.getElementById('whatsapp');

       emailDiv.classList.add('hide');
       waDiv.classList.remove('hide');

    } ;

    useEffect(() => {
        const sendemail = async () => {
            try {
                const response = await axios.post('https://test.arivomkadai.com/api/verification/', Email);
                const result = response.data;
               // console.log(result);
            } catch (error) {
                console.error('Mail not Sent:', error);
            }
        };

        sendemail(); 
    }, []); 


    const whatsapp =()=>{
        
    }


    const getWhatsappOtp =() =>{

        const waDiv = document.getElementById('whatsapp');
        const otpDiv = document.getElementById('whatsappotp');
        
        waDiv.classList.add('hide');
        otpDiv.classList.remove('hide');


        //--send otp to whastapp

    }
  return (
    <main class="main pages">
    <div class="page-content  pb-150">
        <div class="container">
            <div class="row" align="center" style={{marginTop:'40vh'}}>
                <div class="col-xl-4 col-lg-6 col-md-12 m-auto" id="email">
                    <input type="text" value="" placeholder='Enter OTP sent to your Email'/>
                    <button class="btn btn-heading btn-block hover-up mt-15">Verify</button><br></br>
                    <button onClick ={getWhatsapp}  class="btn btn-fill-out btn-block hover-up font-weight-bold mt-15">Get OTP in WhatsApp</button>                       
                </div>

                <div class="col-xl-4 col-lg-6 col-md-12 m-auto hide" id="whatsapp">
                    <input type="text" value="" placeholder='Enter your WhatsApp Number'/>
                    <button onClick ={getWhatsappOtp} class="btn btn-heading btn-block hover-up mt-15">Get OTP</button><br></br>
                </div>


                <div class="col-xl-4 col-lg-6 col-md-12 m-auto hide" id="whatsappotp">
                    <input type="text" value="" placeholder='Enter OTP shared to your WhatsApp Number'/>
                    <button class="btn btn-heading btn-block hover-up mt-15">Verify</button><br></br>
                </div>
           </div>
        </div>   
    </div>
    </main>
  )
}
export default Test;
