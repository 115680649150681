import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Header from './headerpage';
import Footer from './footer';
import gsap from 'gsap';
import '../css/img-scroll.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


function App() {

    const queryParameters = new URLSearchParams(window.location.search)
    const category = queryParameters.get("id") ;
    //console.log(category);
    const [productData, setproductData] = useState(null);

  useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.post('https://test.arivomkadai.com/api/category/', {category: category, limit: 'no'});
      const products = response.data.data.filter(item =>item.visibility ==='0');
      setproductData(products);
     //console.log(response.data);
    } catch (error) {
      
      console.error("Error fetching data:", error);
    }
  };

  fetchData();

}, []);


  
    return (
       <>
    <div class="sidebars">
	  <a href="/offline">
	    <h3 class="bar-content">Arivom Products</h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main mt-bar">
       
  
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/online-products' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span>{category}
                </div>
            </div>
        </div>

        {productData &&  productData.length ? (
      
        <section class="product-tabs section-padding position-relative">
            <div class="container">
                <div class="section-title style-2 wow animate__animated animate__fadeIn">
                    <h3>{category} Products</h3>
                </div>
             
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                        <div class="row product-grid-4">
						
						{productData &&  productData.map(product => (
                            <div class="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                                    <div class="product-img-action-wrap">
                                        <div class="product-img product-img-zoom">
                                            <a href={`product?id=${product.p_id}`}>
                                                <img class="default-img" src={`https://test.arivomkadai.com/api/product_data/${product.image}`} alt={product.product} />
                                                <img class="hover-img" src={`https://test.arivomkadai.com/api/product_data/${product.image}`} alt={product.product} />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="product-content-wrap">
                                       
                                        <h2><a href={`product?id=${product.p_id}`}>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a></h2>
                                        <div class="product-rate-cover">
                                            <div class="product-rate d-inline-block">
                                                <div class="product-rating" style={{width: product.rating*20 +'%'}}></div>
                                            </div>
                                            <span class="font-small ml-5 text-muted"> ({product.review}) ratings</span>
                                        </div>
                                       
                                        <div class="product-card-bottom">
                                            <div class="product-price">
                                                <span>₹ {product.price}</span>
                                            </div>
                                            <div class="add-cart">
                                                <a class='add' href={`product?id=${product.p_id}`}><i class="fi-rs-shopping-cart mr-5"></i>View </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            ))}
                          
                        </div>
                  
                    </div>
                   
                </div>
             
            </div>
        </section>

        ) : (
            
            <section class="home-slider position-relative mb-30 mt-50" style={{minHeight:'90vh'}}>
            <div class="container">
                <div class="home-slide-cover mt-30">
                    <div class="hero-slider-1 ">
                        <div class="single-hero-slider single-animation-wrap" style={{backgroundImage: 'url(assets/imgs/slider/slider-2.png)'}}>
                            <div class="slider-content container">
                                <h3>
                                   No Products Available at this moment. Please check back later!.
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        )}
       
    </main>

    <Footer />

    </div>
    </>
    );
}

export default App;