import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './headerpages';
import Footer from './footeroffline';
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
import Footeroffline from './footeroffline';
import ContentEditable from 'react-contenteditable'
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Preorderproduct() {
    const navigate = useNavigate();
    const userId = ReactSession.get("arkuserId");
    const otpStatus = ReactSession.get("otp");
    const [productData, setProductData] = useState(null);
    const [productCatData, setCatProductData] = useState(null);
   
    const [category, setCategory] = useState(null);
  //  console.log("cat:", category);
    const queryParameters = new URLSearchParams(window.location.search)
    const productId = queryParameters.get("id");
    const [preorderquantity, setpreorderQuantity] = useState(1);
    const [error, setError]=useState({});
    
    
    
       
    const handlepreorderIncrement = () => {
      //console.log("Product:", productId);
      if (preorderquantity < 10) {
          setpreorderQuantity(prevCount => prevCount + 1);
          updatedpreCart(preorderquantity + 1); 
      }else{
        setpreorderQuantity(10);
      }
    };
    
    const handlepreorderDecrement = () => {
      if (preorderquantity > 1) {
          setpreorderQuantity(prevCount => prevCount - 1);
          updatedpreCart( preorderquantity - 1); 
      }
    };
    const updatedpreCart = (newQuantity) => {
      // console.log('Updating cart with quantity:', newQuantity);
     };

     const productquantity=(e)=>{
        const value=  parseInt(e.target.value);
        if(value){

        
        if(value >10){
            setpreorderQuantity(10);
        }else{
        setpreorderQuantity(value);
        }
    }
     }

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://test.arivomkadai.com/api/editpreorderproduct/?id=${productId}`);
            setProductData(response.data);
            console.log(response.data);
           // console.log(stockValue);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, [productId]);

      useEffect(() => {
        if (productData && productData.data && productData.data.length > 0) {
          const categoryValue = productData.data[0].cat;
          setCategory(categoryValue);
        }
      }, [productData]);




      useEffect(() => {
        const fetchCatData = async () => {
          try {
            const response = await axios.post('https://test.arivomkadai.com/api/preorderproduct/', {limit: 'yes'}) ;
            setCatProductData(response.data);
           // console.log(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchCatData();
      }, [category]);

      
      const categories = productCatData && productCatData.data.filter(item => item.category === category); // Filter product data based on the category ID
//console.log("Filtered categories:", categories);

      const openModal = () => {
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
    };

    const copyButtonClick = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        alert('Link Copied to Clipboard.');
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
      };
 
      
      
      
      const addTowish = async(event) => {
       
        if(userId > 0 && otpStatus == 'no'){
            navigate('/otp');
         }
        else{
        const wishitem = {
            cust_id: userId,
            id: productId,
            product: productData?.data[0]?.product,
            price: productData?.data[0]?.price,
            image: productData?.data[0]?.image
        }
        try {
            const response = await axios.post(`https://test.arivomkadai.com/api/addwish/`, wishitem);
            const message = response.data.message;
            
            if(response.data.status === 'success'){
                alert(message);
            }
           
        } catch (error) {
            console.error('There was a problem with login:', error);
            setError('Not Inserted');
        }
       
    }  
      }


      
   
const openModalspre = () => {
    const modal = document.getElementById('copyModals');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModalspre = () => {
    const modal = document.getElementById('copyModals');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };

    const [warning, setWarning] = useState('no');
  
    const handleYesClick = () => {
        setWarning('yes');
        sessionStorage.removeItem('cartItems');
        closeModalspre(); // Close the modal after setting the warning
    };
    
    const handleNoClick = () => {
        setWarning('no');
        closeModalspre(); // Close the modal after setting the warning
    };
    
    
   useEffect(() => {
  const storedPreorderItem = JSON.parse(sessionStorage.getItem('cartItems'));
  if(storedPreorderItem == null || storedPreorderItem.length == 0){
        setWarning('yes');
  }
}, []);



const openModalsalready = () => {
    const modal = document.getElementById('copyModalsready');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
      
    }
    
    
    
  };
  
  const closeModalsalready = () => {
    const modal = document.getElementById('copyModalsready');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };



      const addToCart = () => {
        if (warning === 'yes') {
            sessionStorage.removeItem('cartItems');
        // Get the existing cart items from session storage
      const existingpreorderCartItems = JSON.parse(sessionStorage.getItem('preorder')) || [];
    
    // Check if the product is already in the cart
      const existingpreorderItemIndex = existingpreorderCartItems.findIndex(item => item.id === productId);
    
    if (existingpreorderItemIndex !== -1) {
        
           if(preorderquantity>0){
            openModalsalready(); 
            //existingpreorderCartItems[existingpreorderItemIndex].quantity += preorderquantity;
           }else{
            setpreorderQuantity(0);
           }
         
        } else {
            // If the product is not in the cart, add it as a new item
           const newItem = {
               id: productId,
               code: productData[0]?.data[0]?.product_code,
                product: productData?.data[0]?.product,
                price: productData?.data[0]?.price,
                quantity: preorderquantity,
                image: productData?.data[0]?.image
             };
    
            // Add the new item to the cart
            existingpreorderCartItems.push(newItem);
        }
    
        // Store the updated cart items back in session storage
        sessionStorage.setItem('preorder', JSON.stringify(existingpreorderCartItems));
         alert(`${productData?.data[0]?.product} added to cart!`);
    }else{
        openModalspre();
    }
  };
    



    
    

    const [isReadMore, setIsReadMore] = useState(true);

      const toggleDescription = () => {
    
        // const text = document.getElementById('disc');
        // text.classList.remove('description');
        // text.classList.add('disc1');
        setIsReadMore(!isReadMore);
        
      };

  const product = productData?.data?.[0];
  const imageUrl = `https://test.arivomkadai.com/api/product_data/${product?.image}` || "https://arivomkadai.com/favicon.png";
  const productTitle = product?.product || "ArivomKadai - We Make Your Shopping Simple";
  const productDescription = product?.content || "ArivomKadai - We Make Your Shopping Simple";

  return (
       <>
  <HelmetProvider>   
    <Helmet>
      <title>{productTitle}</title>
      <link rel="canonical" href={`https://arivomkadai.com/preorderproduct?id=${productId}/`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={productTitle} />
      <meta property="og:description" content={productDescription} />
      <meta property="og:url" content={`https://arivomkadai.com/preorderproduct?id=${productId}/`} />
      <meta property="og:site_name" content="ArivomKadai" />
      <meta property="og:updated_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:alt" content={productTitle} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="article:published_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="article:modified_time" content="2024-08-17T13:12:25+00:00" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={productTitle} />
      <meta name="twitter:description" content={productDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="ArivomKadai" />
      <meta name="twitter:label2" content="Time to read" />
      <meta name="twitter:data2" content="2 minutes" />
    </Helmet>
    </HelmetProvider> 

  
    <div class="sidebars">
    <a  className="sidebars-on" href="/online-products">
	    <h3 class="bar-content">online Products </h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main mt-3 d-mt-10">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span>
                      {productData && ( productData.data[0].product )}
                </div>
            </div>
        </div>


        <div class="modal fade custom-modal" id="copyModalsready" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalsalready}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">{productData && ( productData.data[0].product )}is already in cart</p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal1 fade custom-modal" id="copyModals" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" style={{maxWidth: '35%'}}>
                                                        <div class="modal-content">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalspre}></button>
                                                            <div class="modal-body bg-remover">
                                                                <div class="login_wrap widget-taber-content background-white">
                                                                <div class="padding_eight_all bg-white" align="center">
                                                                        <div class="heading_s1">
                                                                            <h4 class="mb-5">Warning</h4>
                                                                            <p class="mb-30">Your cart contains items from Regular Products. Would you like to reset your cart for adding items from Pre-Order Products?</p>
                                                                        </div>
                                                                        <button className="button mr-10 mt-10" onClick={handleYesClick}><i className="fa-regular fa-copy"></i> Yes</button>
                                                                        <button className="button mr-10 mt-10" onClick={handleNoClick}><i className="fa-regular fa-copy"></i> No</button>
                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
        </div>





        <div class="container mb-30">
            <div class="row">
                <div class="col-xl-12 col-lg-12 m-auto">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="product-detail accordion-detail">
                                <div class="row mb-50 mt-30">
                                    <div class="col-md-4 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                        <div class="detail-gallery">
                                        <span class="zoom-icon" onClick={openModal}><i class="fi-rs-share"></i></span>
                                            <div class="modal1 fade custom-modal" id="copyModal" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" style={{maxWidth: '35%'}}>
                                                        <div class="modal-content">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                                                            <div class="modal-body bg-remover">
                                                                <div class="login_wrap widget-taber-content background-white">
                                                                <div class="padding_eight_all bg-white">
                                                                        <div class="heading_s1">
                                                                            <h4 class="mb-5">Share</h4>
                                                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                                                        </div>
                                                                        <a class="button mr-10 mt-10" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                                                                        <a class="button mr-10 mt-10" href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-twitter"></i></a>
                                                                        <a class="button mr-10 mt-10" href={`https://api.whatsapp.com/send?text=Check%20out%20this%20awesome%20Product%21%20https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-whatsapp"></i></a>
                                                                         <button class="button mr-10 mt-10" onClick={() => copyButtonClick(`https://arivomkadai.com/preorderproduct?id=${productId}`)}><i class="fa-regular fa-copy"></i> Copy</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div class="product-image-slider" style={{contentVisibility:'visible'}}>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image !== '' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image}`}  alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                     </>)}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image1 !== 'no_image.webp' && (<>
                                                   {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image1}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                     </>)}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image2 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image2}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                     </>)}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image3 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image3}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                     </>)}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].video !== '' && (<>
                                                {productData && (
  productData.data[0].video.endsWith('.mp4') ? (
    <video src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].video}`} width="100%" autoPlay loop muted controls></video>
  ) : (
    <iframe width="100%" height="560" src={`https://www.youtube.com/embed/${productData.data[0].video}?autoplay=1&loop=1&mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  )
)}
</>)}
                                                </figure>
                                            </div>
                                  
                                            <div class="slider-nav-thumbnails">
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image !== '' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                     </>)}
                                                </div>
                                                <div>
                                                  {productData && productData.data && productData.data.length > 0 && productData.data[0].image1 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image1}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                    </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image2 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image2}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                    </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image3 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image3}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                    </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].video !== '' && (<>
                                                    {productData && (
  productData.data[0].video.endsWith('.mp4') ? (
    <video src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].video}`} width="100%" autoPlay loop muted controls></video>
  ) : (
    <iframe width="100%" height="60" src={`https://www.youtube.com/embed/${productData.data[0].video}?autoplay=1&loop=1&mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  )
)}
  </>)}
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div class="col-md-8 col-sm-12 col-xs-12">
                                        <div class="detail-info pr-30 pl-30">
                                            Product Code : <span class="stock-status out-stock"> {productData && ( productData.data[0].product_code )}</span>
                                            <h2 class="title-detail">{productData && ( productData.data[0].product )}</h2>
                                            <div class="short-desc mb-30">
                                            <p className="font-lg description"  id="disc">
                                            {productData && (
                                                <span>
                                                      {isReadMore
                                                        ?  <ContentEditable html={`${productData.data[0].content.slice(0, 200)}`}  style={{ listStyleType: 'decimal' }}/>
                                                        :  <ContentEditable html={`${productData.data[0].content}`}  style={{ listStyleType: 'decimal' }}/>}
                                                      {productData.data[0].content.length > 200 && (
                                                        <button id="readMoreBtn" onClick={toggleDescription}>
                                                          {isReadMore ? '...read more' : ' show less'}
                                                        </button>
                                                      )}
                                                </span>
                                                )}
                                            </p>
                                             </div>
                                            <div class="clearfix product-price-cover">
                                                <div class="product-price primary-color float-left">
                                                    <span class="current-price text-brand">₹ {productData && ( productData.data[0].price )}</span>
                                                </div>
                                            </div>
                                           

                                            <div className="detail-extralink mb-50 mb-51" align="center" >
                                            <button className="btn1" onClick={handlepreorderDecrement}>-</button>
                                           
      <div className="detail-qty border radius">
        
     
      <input
        type="text"
        name="quantity"
        className="qty-val"
        value={preorderquantity}
        min="1"
        onChange={productquantity}
      />
      </div>
      <button className="btn1"  onClick={handlepreorderIncrement}>+ </button>
      <div className="product-extra-link2">
        <button onClick={addToCart} className="button button-add-to-cart">
          <i className="fi-rs-shopping-cart"></i>Add to cart
        </button>
        {/* <button onClick={addTowish} class="button button-add-to-cart ml-5"><i class="fi-rs-heart"></i>Wishlist</button>
                                             */}
      </div>
    </div>
                                           
                                        </div>
                                     
                                    </div>
                                </div>
                                
                                {/* <div class="row mt-60">
                                    <div class="col-12">
                                        <h2 class="section-title style-1 mb-30">Related products</h2>
                                    </div>
                                    <div class="col-12">
                                        <div class="row related-products">

                                        {categories && Array.isArray(categories) && categories.map(product => (

                                            <div class="col-lg-3 col-md-4 col-12 col-sm-6">
                                                <div class="product-cart-wrap hover-up">
                                                    <div class="product-img-action-wrap">
                                                        <div class="product-img product-img-zoom ">
                                                            <a href={`preorderproduct?id=${product.p_id}`} tabindex='0'>
                                                                <img class="default-img" src={`https://test.arivomkadai.com/api/product_data/${product.image}`} alt={product.product} />
                                                                <img class="hover-img" src={`https://test.arivomkadai.com/api/product_data/${product.image}`} alt={product.product} />
                                                            </a>
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="product-content-wrap">
                                                        <h2><a href={`preorderproduct?id=${product.p_id}`} tabindex='0'>{product.product}</a></h2>
                                                      
                                                        <div class="product-price">
                                                            <span>₹ {product.price}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        ))}

                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </main>

    <Footeroffline />

    </div>

    
    </>
    );
}

export default Preorderproduct;