import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './headerpage';
import Footer from './footer';
import { isMobile } from 'react-device-detect';
import ContentEditable from 'react-contenteditable'
import { Helmet, HelmetProvider } from 'react-helmet-async';


function App() {

    const [productData, setProductData] = useState(null);
    const [productCatData, setCatProductData] = useState(null);
    const [category, setCategory] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const productId = queryParameters.get("id")
  

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://test.arivomkadai.com/api/editproduct/index.php?id=${productId}`);
            setProductData(response.data);
          // console.log(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, [productId]);

      useEffect(() => {
        if (productData && productData.data && productData.data.length > 0) {
          const categoryValue = productData.data[0].cat;
        //  console.log(categoryValue);
          setCategory(categoryValue);
        }
      }, [productData]);

      useEffect(() => {
        const fetchCatData = async () => {
          try {
            const response = await axios.post('https://test.arivomkadai.com/api/category/', {category: category, limit: 'yes'}) ;
            setCatProductData(response.data);
          //  console.log(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchCatData();
      }, [category]);

      const openModal = () => {
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
    };

    const copyButtonClick = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        alert('Link Copied to Clipboard.');
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
      };
     
      const buyNow = async () => {
        try {
            const ipAddressResponse = await axios.get('https://api.ipify.org?format=json');
            const ipAddress = ipAddressResponse.data.ip;
            const deviceType = isMobile ? 'Mobile' : 'Desktop';
           // console.log('User IP Address:', ipAddress);
            //console.log('Device Type:', deviceType);
            const deviceInfo = {
                userAgent: navigator.userAgent,
                deviceType: navigator.platform
            }; 
            const formData = {
                id : productId,
                ip : ipAddress,
                device : deviceType
            };
			// console.log('Response Data:', formData);
        const response = await axios.post(`https://test.arivomkadai.com/api/ipaddress/`, formData);
        //console.log('Response Data:', response.data);
        const link = productData && productData.data && productData.data.length > 0 ? productData.data[0].link : '';
        window.open(link, '_blank');
        const dataArray = response.data.data;
if (dataArray.length > 0) {
    const firstItem = dataArray[0];
    const link = firstItem.link;
   // console.log('Link:', link);
    window.location.href = link;
} else {
    console.error('No data found in the response.');
}
} catch (error) {
    console.error('Error processing buy now:', error);
}
};
const postNow = async () => {
    const link = productData && productData.data && productData.data.length > 0 ? productData.data[0].links : '';
    
window.location.href = link;
}


      const [isReadMore, setIsReadMore] = useState(true);

      const toggleDescription = () => {
    
        // const text = document.getElementById('disc');
        // text.classList.remove('description');
        // text.classList.add('disc1');
        setIsReadMore(!isReadMore);
        
      };
    

    const product = productData?.data?.[0];
    const imageUrl = `https://test.arivomkadai.com/api/product_data/${product?.image}` || "https://arivomkadai.com/favicon.png";
    const productTitle = product?.product || "ArivomKadai - We Make Your Shopping Simple";
    const productDescription = product?.content || "ArivomKadai - We Make Your Shopping Simple";
      
    return (
       <>
    <HelmetProvider>   
    <Helmet>
      <title>{productTitle}</title>
      <link rel="canonical" href={`https://arivomkadai.com/product?id=${productId}/`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={productTitle} />
      <meta property="og:description" content={productDescription} />
      <meta property="og:url" content={`https://arivomkadai.com/product?id=${productId}/`} />
      <meta property="og:site_name" content="ArivomKadai" />
      <meta property="og:updated_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:alt" content={productTitle} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="article:published_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="article:modified_time" content="2024-08-17T13:12:25+00:00" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={productTitle} />
      <meta name="twitter:description" content={productDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="ArivomKadai" />
      <meta name="twitter:label2" content="Time to read" />
      <meta name="twitter:data2" content="2 minutes" />
    </Helmet>
    </HelmetProvider> 

    
   <div class="sidebars">
	  <a href="/offline">
	    <h3 class="bar-content">Arivom Products</h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main mt-3 d-mt-10">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/online-products' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <a href='/'>{category}</a> <span></span> {productData && ( productData.data[0].product )}
                </div>
            </div>
        </div>
        <div class="container mb-30">
            <div class="row">
                <div class="col-xl-12 col-lg-12 m-auto">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="product-detail accordion-detail">
                                <div class="row mb-50 mt-30">
                                    <div class="col-md-4 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                        <div class="detail-gallery">
                                        <span class="zoom-icon" onClick={openModal}><i class="fi-rs-share"></i></span>
                                            <div class="modal1 fade custom-modal" id="copyModal" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" style={{maxWidth: '35%'}}>
                                                        <div class="modal-content">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                                                            <div class="modal-body bg-remover">
                                                                <div class="login_wrap widget-taber-content background-white">
                                                                <div class="padding_eight_all bg-white">
                                                                        <div class="heading_s1">
                                                                            <h4 class="mb-5">Share</h4>
                                                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                                                        </div>
                                                                        {/* <a class="button mr-10 mt-10" href="https://www.facebook.com/sharer/sharer.php?u=https://www.sninfoserv.com/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                                                                        <a class="button mr-10 mt-10" href="https://twitter.com/intent/tweet?url=https://www.sninfoserv.com/" target="_blank"><i class="fa-brands fa-twitter"></i></a>
                                                                        <a class="button mr-10 mt-10" href="https://api.whatsapp.com/send?text=Check%20out%20this%20awesome%20Product!%20https%3A%2F%2Fsninfoserv.com" target="_blank"><i class="fa-brands fa-whatsapp"></i></a> */}
                                                                        <a class="button mr-10 mt-10" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
<a class="button mr-10 mt-10" href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-twitter"></i></a>
<a class="button mr-10 mt-10" href={`https://api.whatsapp.com/send?text=Check%20out%20this%20awesome%20Product%21%20https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-whatsapp"></i></a>

                                                                        <button class="button mr-10 mt-10" onClick={() => copyButtonClick(`https://arivomkadai.com/product?id=${productId}`)}><i class="fa-regular fa-copy"></i> Copy</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div class="product-image-slider"style={{contentVisibility:'visible'}}>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image !== '' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image}`}  alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                    </>)}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image1 !== 'no_image.webp' && (<>
                                                   {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image1}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                    </>)}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image2 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image2}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                    </>)}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image3 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image3}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                    </>)}
                                                </figure>
                                                <figure className="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].video !== '' && (
  <>
    
      {productData.data[0].video.endsWith('.mp4') ? (
        <video src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].video}`}  width="100%" autoPlay loop muted controls
        ></video>
      ) : (
        <iframe width="100%" height="560" src={`https://www.youtube.com/embed/${productData.data[0].video}?autoplay=1&loop=1&mute=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
        ></iframe>
      )}
    
  </>
)}
</figure>
                                            </div>
                                  
                                            <div class="slider-nav-thumbnails">
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image !== '' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                     </>)}
                                                </div>
                                                <div>
                                                    {productData && productData.data && productData.data.length > 0 && productData.data[0].image1 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image1}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                    </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image2 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image2}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                    </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image3 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].image3}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                    </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].video !== '' && (
  <>
    
      {productData.data[0].video.endsWith('.mp4') ? (
        <video src={`https://test.arivomkadai.com/api/product_data/${productData.data[0].video}`}  width="100%" autoPlay loop muted controls
        ></video>
      ) : (
        <iframe width="100%" height="60" src={`https://www.youtube.com/embed/${productData.data[0].video}?autoplay=1&loop=1&mute=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
        ></iframe>
      )}
    
  </>
)}
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div class="col-md-8 col-sm-12 col-xs-12">
                                        <div class="detail-info pr-30 pl-30">
                                            Product Code : <span class="stock-status out-stock"> {productData && ( productData.data[0].product_code )}</span>
                                            <h2 class="title-detail">{productData && ( productData.data[0].product )}</h2>
                                            <div class="short-desc mb-30">
                                            <p className="font-lg description"  id="disc">
                                           
                                               {productData && (
                                                <span>
                                                      {isReadMore
                                                        ?  <ContentEditable html={`${productData.data[0].content.slice(0, 200)}`}  style={{ listStyleType: 'decimal' }}/>
                                                        :  <ContentEditable html={`${productData.data[0].content}`}  style={{ listStyleType: 'decimal' }}/>}
                                                      {productData.data[0].content.length > 200 && (
                                                        <button id="readMoreBtn" onClick={toggleDescription}>
                                                          {isReadMore ? '...read more' : ' show less'}
                                                        </button>
                                                      )}
                                                </span>
                                                )}
                                            </p>
                                            </div>
                                            <div class="clearfix product-price-cover">
                                                <div class="product-price primary-color float-left">
                                                    <span class="current-price text-brand">₹ {productData && ( productData.data[0].price )}</span>
                                                </div>
                                            </div>
                                            <div class="product-detail-rating">
                                            <div class="product-rate-cover text-end">
                                                ({productData &&  productData.data[0].rating})
                                                    <div class="product-rate d-inline-block m-b-1">
                                                        <div class="product-rating" style={{width: productData && ( productData.data[0].rating)*20 + '%'}}></div>
                                                    </div>
                                                    <span class="font-small ml-5 text-muted"> {productData && ( productData.data[0].review )} ratings </span>
                                                </div>
                                            </div>

                                            <div class="detail-extralink mb-50 mb-51">
                                              <div class="product-extra-link2">
                                                <button onClick={buyNow} class="button button-add-to-cart"><i class="fi-rs-shopping-cart"></i>Buy Now</button>
                                              </div>
                                            </div>
                                            <div class="detail-extralink mb-50">
                                              <div class="product-extra-link2">
                                                <button onClick={postNow} class="button button-add-to-cart"><i class="fa-brands fa-twitter"></i>Review</button>
                                              </div>
                                            </div>
                                           
                                        </div>
                                     
                                    </div>
                                </div>
                                
                                <div class="row mt-60">
                                    <div class="col-12">
                                        <h2 class="section-title style-1 mb-30">Related products</h2>
                                    </div>
                                    <div class="col-12">
                                        <div class="row related-products">

                                        {productCatData && productCatData.data && productCatData.data.map(productCat => (

                                            <div class="col-lg-3 col-md-4 col-12 col-sm-6">
                                                <div class="product-cart-wrap hover-up">
                                                    <div class="product-img-action-wrap">
                                                        <div class="product-img product-img-zoom ">
                                                            <a href={`product?id=${productCat.p_id}`} tabindex='0'>
                                                                <img class="default-img" src={`https://test.arivomkadai.com/api/product_data/${productCat.image}`} alt={productCat.product} />
                                                                <img class="hover-img" src={`https://test.arivomkadai.com/api/product_data/${productCat.image}`} alt={productCat.product} />
                                                            </a>
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="product-content-wrap">
                                                        <h2><a href={`product?id=${productCat.p_id}`} tabindex='0'>{productCat.product.length > 50 ? `${productCat.product.slice(0, 50)}...` : productCat.product}</a></h2>
                                                        <div class="product-rate d-inline-block">
                                                           <div class="product-rating" style={{width: productCat.rating*20 +'%'}}></div>
                                                        </div>
                                                        <div class="product-price">
                                                            <span>₹ {productCat.price}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </main>

    <Footer />

    </div>

    
    </>
    );
}

export default App;