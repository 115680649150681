import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

const RazorpayCheckout = ({ razId, Amount, orderId, ordFrm }) => {

const navigates = useNavigate();
const Name = ReactSession.get("userName");
const Email = ReactSession.get("email");
const Phone = ReactSession.get("phonenumber");

const amount = parseInt(Amount) * 100;

const handlePaymentButtonClick = (rzp1, e) => {
  var popup = document.getElementById("loader");
  popup.classList.toggle("show");
  rzp1.open();
  e.preventDefault();
};

const updateOrder = async (pId) => {
  try {
    const response = await axios.post(`https://test.arivomkadai.com/api/razorpay/`, {order_id: orderId, order_from: ordFrm, payment_id: pId });
    const result = response.data;
    //console.log(result);
    sessionStorage.removeItem('cartItems');
    sessionStorage.removeItem('offer');
    sessionStorage.removeItem('deliveryAmount');
    sessionStorage.removeItem('preorder');
    navigates(`/paymentstatus?id=${orderId}`);
  } catch (error) {
    console.error('Error fetching old data:', error);
  }
};

useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  script.async = true;
  document.body.appendChild(script);

 
  script.onload = () => {
    const options = {
      key: 'rzp_live_tI1O9zo2ALXhtO', 
      amount: amount, 
      currency: 'INR',
      order_id: razId,
      name: 'ArivomKadai',
      description: 'ArivomKadai',
      image: 'https://arivomkadai.com/favicon.png',
      
      handler: function (response) {
        const pId = response.razorpay_payment_id;
        updateOrder(pId);
      },
      prefill: {
        name: Name,
        email: Email,
        contact: Phone
      },
      notes: {
        address: 'Razorpay Corporate Office'
      },
      theme: {
        color: '#fe940c'
      }
    };

    //console.log(options);

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      alert(response.error.description);
      navigates(`/checkout`);
    });

    // Assign the click handler
    document.getElementById('rzp-button1').onclick = (e) => handlePaymentButtonClick(rzp1, e);
  };

  return () => {
    document.body.removeChild(script);
  };
}, [razId, Amount, orderId, ordFrm]);

// Function to trigger the payment button click programmatically


// Example usage: Call this function to simulate the button click from another function
// triggerPayment();
return (
  <div>
    <button id="rzp-button1" style={{ display: 'none' }}>Pay Now</button>
    <div id="loader" style={{ display: 'none' }}>Loading...</div>
  </div>
);
};export default RazorpayCheckout;