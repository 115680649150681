import React,{useEffect, useState} from 'react'
import Header from './headerpages';
import Footer from './footer';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
ReactSession.setStoreType("localStorage");


function Ordersdetail() {
    const navigate = useNavigate();
    const userId = ReactSession.get("arkuserId");
    const otpStatus = ReactSession.get("otp");
    const userName = ReactSession.get("userName");
    const Email = ReactSession.get("email");
  const Profile = ReactSession.get("profile");
    const [orders, setOrders] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    
    if(userId > 0 && otpStatus == 'no'){
        navigate('/otp');
     }




    useEffect(() => {
    const fetchData = async () => {
         const formData = {id:orderId};
        // console.log("user:",formData);
       try {
         const response = await axios.post(`https://test.arivomkadai.com/api/processsucess/`, formData);
 
         setOrders(response.data);
        // console.log(response.data);
       } catch (error) {
         
         console.error("Error fetching data:", error);
       }
     };



     
     fetchData();
    }, [orderId]); 




     //logout
     const handleLogout = () => {
        ReactSession.set("arkuserId", "0");
        ReactSession.set("userName", "");
        ReactSession.set("email", "");
        ReactSession.set("profile", "");
        ReactSession.set("phonenumber", "");
        ReactSession.set("gender", "");
        sessionStorage.removeItem('cartItems');
        // Perform any other cleanup tasks or redirection if needed
     navigate('/login'); // Redirect to login page after logout
    }
  return (
    <>
  
    <div className="sidebars" >
    <a  className="sidebars" href="/online-products">
         <h3 className="bar-content">Online Products</h3>
         </a>
     </div>
     
 <div class="main-bar">
 
   <Header  />
   <main class="main pages">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                     <span></span> My Account
                </div>
            </div>
        </div>
        <div class="page-content pt-150 pb-150">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 m-auto">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="author-image mb-30 col-lg-5 col-sm-6 col-12">
                                    <a ><img src={`https://test.arivomkadai.com/api/customer_data/${Profile}`} alt="" class="avatar"/></a>
                                    <div class="author-infor">
                                        <h5 class="mb-5">{userName}</h5>
                                        <p class="mb-0 text-muted font-xs">
                                            <span class="mr-10">{Email}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="dashboard-menu">
                                    <ul class="nav flex-column" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="dashboard-tab" data-bs-toggle="tab" href="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false"><i class="fi-rs-settings-sliders mr-10"></i>Dashboard</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class='nav-link' href='/account'><i class="fi-rs-sign-out mr-10"></i>Account</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class='nav-link' onClick={handleLogout}><i class="fi-rs-sign-out mr-10"></i>Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="tab-content account dashboard-content pl-50">
                                    <div class="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                    
                                  {orders && orders.data && orders.data.orderdetails && orders.data.orderdetails.map(order=> (
                                    
                                    <div class="loop-grid loop-list pr-30 mb-50" key={order.id}>
                            <article class="wow fadeIn animated hover-up mb-30 animated">
                            <img src={`https://test.arivomkadai.com/api/product_data/${order.img}`} width="50%" alt='product' />
                                
                                <div class="entry-content-2 pl-50">
                                    <h3 class="post-title mb-20">
                                        <a >{order.product}</a><br></br>
                                       
                                    </h3>
                                    <span>Order Id : <span>{order.id}</span></span><br></br>
                                    <span class='cart-title'>Qty: {order.quantity}</span><br></br>
                                    <span class='cart-title'>price: {order.price}</span><br></br>
                          <ul class='order-summery'>
                            <li>
                              <span>Placed on: {orders.data.date}</span>
                            </li>

                            <li>
                              <span> Order Status: {orders.data.status}</span>
                            </li>

                          </ul>
                                    <p class="post-exerpt mb-40">Address: {orders.data.address}</p>
                                    <div class="add-cart">
                                                                    <a class="btn" href={`/track?id=${order.id}`}>Track</a>
                                                                </div>
                                </div>
                            </article>
                           
                        </div>
                        ))} 
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
 
     <Footer />
 
     </div>
     </>
  )
}
export default  Ordersdetail;
