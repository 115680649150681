import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Header from './headerpage';
import Footer from './footer';
import Slider from './slider';
import gsap from 'gsap';
import '../css/img-scroll.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


function App() {

const [productData, setproductData] = useState(null);
const [banner, setBanner] = useState('');


const fetchDatabanner = async () => {
  try {
    const response = await axios.get('https://test.arivomkadai.com/api/onlinebanner/');
    const datas = response.data.data.find(item => item.id === '1');
        setBanner(datas); 
        //console.log(datas);
   
  } catch (error) {   
    console.error("Error fetching data:", error);
  }
};

fetchDatabanner();

  useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get('https://test.arivomkadai.com/api/product/');
      const products = response.data.data.filter(item =>item.visibility ==='0');
      setproductData(products);
     // console.log(response.data);
    } catch (error) {
      
      console.error("Error fetching data:", error);
    }
  };

  fetchData();

  const intervalId = setInterval(() => {
    fetchData();
  }, 5000);
  return () => clearInterval(intervalId);

}, []);



const [sliderData, setSliderData] = useState(null);

  useEffect(() => {

  const fetchSliderData = async () => {
    try {
      const response = await axios.get('https://test.arivomkadai.com/api/slider/');
      setSliderData(response.data);
      //console.log(response.data);
    } catch (error) {
      
      console.error("Error fetching data:", error);
    }
  };

  fetchSliderData();


}, []);


useEffect(() => {
  gsap.registerPlugin(ScrollTrigger);

  let timeoutId = setTimeout(() => {

  const cols = gsap.utils.toArray(".cols");

  cols.forEach((col, i) => {
    const images = col.childNodes;
    images.forEach((image) => {
      var clone = image.cloneNode(true);
      col.appendChild(clone);
    });
  
    images.forEach((item) => {
      let columnHeight = item.parentElement.clientHeight;
      let direction = i % 2 !== 0 ? "+=" : "-="; 

      gsap.to(item, {
        y: direction + Number(columnHeight / 2),
        duration: 30,
        repeat: true,
        ease: "none",
        modifiers: {
          y: gsap.utils.unitize((y) => {
            return y;
          })
        }
      });
    });
  });

}, 2000);
  
  
  
  const imagesScrollerTrigger = ScrollTrigger.create({
    trigger: "section",
    start: "top 50%",
    end: "bottom 50%",
    onUpdate: function (self) {
     // console.log("Velocity:", self.getVelocity());
    }
  });


  return () => {
    if (imagesScrollerTrigger) {
      imagesScrollerTrigger.kill();
    }
  };


}, []);


useEffect(() => {

  const imagesScrollerTrigger = ScrollTrigger.create({
    trigger: "section",
    start: "top 50%",
    end: "bottom 50%",
    onUpdate: function (self) {
      //console.log("Velocity:", self.getVelocity());
    }
  });


  return () => {
    if (imagesScrollerTrigger) {
      imagesScrollerTrigger.kill();
    }
  };


}, []);
  
    return (
       <>
    <div class="sidebars">
	  <a href="/offline">
	    <h3 class="bar-content">Arivom Products</h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main mt-bar">
        <section class="home-slider position-relative mb-30 mt-50" style={{minHeight:'90vh'}}>
            <div class="container">
                <div class="home-slide-cover mt-30">
                    <div class="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                    <div class="single-hero-slider single-animation-wrap" style={{backgroundImage: `url(https://test.arivomkadai.com/api/banner_data/${banner && banner.image})`}}>
                          <div class="slider-content">
                              <h1 class="display-1 mb-40">
                                 {banner && banner.heading}
                              </h1>
                              <p class="text-white">{banner && banner.text}</p>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
  
        <section class="banners mb-25 " >
          <div class="container">
            <div class="gallery mob-slider">

            { sliderData && sliderData.data && sliderData.data.map((array, index) => (
              
              <div className="cols" key={index}>
                {array.map((item, itemIndex) => (

                    <div class="image" key={itemIndex}>
                     <a href={`product?id=${item.p_id}`}><img src={`https://test.arivomkadai.com/api/product_data/${item.image}`} alt={item.product_name}  /> </a> 
                    </div>

                ))}

              </div>

            ))}
             

            </div>
          </div>
        </section>
      
        <section class="product-tabs section-padding position-relative ">
            <div class="container">
                <div class="section-title style-2 wow animate__animated animate__fadeIn ">
                    <h3>Popular Products</h3>
                </div>
             
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                        <div class="row product-grid-4">
						
						{productData  && productData.slice(-20).reverse().map(product => (
                            <div class="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                                    <div class="product-img-action-wrap">
                                        <div class="product-img product-img-zoom">
                                            <a href={`product?id=${product.p_id}`}>
                                                <img class="default-img" src={`https://test.arivomkadai.com/api/product_data/${product.image}`} alt={product.product} />
                                                <img class="hover-img" src={`https://test.arivomkadai.com/api/product_data/${product.image}`} alt={product.product} />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="product-content-wrap">
                                       
                                        <h2><a href={`product?id=${product.p_id}`}>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a></h2>
                                        <div class="product-rate-cover">
                                        ({product.rating})
                                            <div class="product-rate d-inline-block m-b-2">
                                                <div class="product-rating" style={{width: product.rating*20 +'%'}}></div>
                                            </div>
                                            <span class="font-small ml-5 text-muted"> {product.review} ratings </span>
                                        </div>
                                       
                                        <div class="product-card-bottom">
                                            <div class="product-price">
                                                <span>₹ {product.price}</span>
                                            </div>
                                            <div class="add-cart">
                                                <a class='add' href={`product?id=${product.p_id}`}><i class="fi-rs-shopping-cart mr-5"></i>View </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            ))}
                          
                        </div>
                  
                    </div>
                   
                </div>
             
            </div>
        </section>
       
    </main>
    <Slider/>
    <Footer />

    </div>
    </>
    );
}

export default App;