import React, { useState } from 'react';
import Header from './header';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");



 function Register() {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [input, setInput] = useState({});
    
    const handleChange=(event)=>{
        const name = event.target.name;
        const value =event.target.value;
        setInput(values => ({...values, [name]: value}))
    }

    const handleSubmit =async(event)=>{
        event.preventDefault();
   // console.log(input);
    try {
        const response = await axios.post('https://test.arivomkadai.com/api/register/', input);
        const result =response.data.status;
       // console.log(response.data);
        if(result === 'success'){
            if(response.data.otp === 'no'){
              ReactSession.set("email", input.email);
              ReactSession.set("otp", response.data.otp);
              navigate('/otp');
            }else{
              ReactSession.set("otp", response.data.otp);
              ReactSession.set("arkuserId", response.data.data[0].user_id);
              ReactSession.set("userName", response.data.data[0].name);
              ReactSession.set("email", response.data.data[0].email);
              ReactSession.set("phonenumber", response.data.data[0].phonenumber);
              ReactSession.set("gender", response.data.data[0].gender);
              ReactSession.set("profile", response.data.data[0].profile);
              ReactSession.set("building", response.data.data[0].buiulding);
              ReactSession.set("street", response.data.data[0].street);
              ReactSession.set("city", response.data.data[0].city);
              ReactSession.set("state", response.data.data[0].state);
              ReactSession.set("pincode", response.data.data[0].pincode);
           }



          
              // navigate('/checkout');


        }else{
            const errors = response.data.error.message;
            setMessage(errors);
           
        }
       // console.log(message);
        // const userData = response.data;
        //console.log(response.data.userId);
        // navigate('/login');
        // alert(`Registered successfully!`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
       
    }
   }



  return (
  <>
  <Header  />
   <main class="main pages">
        <div class="page-content  pb-150">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-10 col-md-12 m-auto">
                        <div class="row">
                            <div class="col-lg-6 col-md-8">
                                <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Create an Account</h1>
                                            <p class="mb-30">Already have an account? <a href='/login'>Login</a></p>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div class="form-group">
                                                <input type="text" required="" name="username" placeholder="Enter Full Name" value={input.username} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="email" required="" name="email" placeholder="Enter Email" value={input.email} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input required type="number" name="number" placeholder="Enter Phone Number" value={input.number} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input required type="password" name="password" placeholder="Enter Password" value={input.password} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input required type="text" name="building" placeholder="Enter Flat/Houseno/Building/company/Apartment/Area/streat/sector/village" value={input.building} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input required type="text" name="street" placeholder="Enter Area/street/Sector/village" value={input.street} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input required type="text" name="city" placeholder="Enter Town/city" value={input.city} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                               
                                                <select required type="text" name="state" placeholder="Enter state" value={input.state} onChange={handleChange}>
                                                <option value="" disabled selected hidden>Select State</option>
														 <option value="Tamil Nadu">Tamil Nadu</option>
														 <option value="Kerala">Kerala</option>
														 <option value="Andhra Pradesh">Andhra Pradesh</option>
														 <option value="Karnataka">Karnataka</option>
														 <option value="Other States">Other States</option>
                             </select>
                                            </div>
                                            <div class="form-group">
                                                <input required type="number" name="pincode" placeholder="Enter pincode" value={input.pincode} onChange={handleChange}/>
                                            </div>
                                           
                                            <div class="form-group mb-30">
                                                <button type="submit" class="btn btn-fill-out btn-block hover-up font-weight-bold" >Submit &amp; Register</button>
                                            </div>
                                           
                                            {message && message.length > 0 && (
                                              <p className="text-danger">{message}</p>
                                            )}

                                            </form>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="col-lg-6 pr-30 d-none d-lg-block mt-50">
                                <img class="border-radius-15" src="assets/imgs/page/login-1.png" alt="" />
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </>
  )
}
export default Register;
